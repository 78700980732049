import PropTypes from 'prop-types'
import {MainImage, getImage} from 'gatsby-plugin-image'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {convertMDContent, media} from '../../../utils'
import PrimaryButton from '../../atoms/PrimaryButton'


const StyledHero = styled.div`
  position: relative;
`

const StyledImage = styled(MainImage)`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: top;

  ${media.down('mobile')(css`
    height: 85vh;
    object-position: top right;
  `)}
`

const StyledNavbarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`

const StyledContentContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 6rem;
  flex-direction: column;
  transform: translateY(-50%);

  ${media.down('mobile')(css`
    top: unset;
    bottom: 2.25rem;
    left: 50%;
    transform: translateX(-50%);
  `)}
`

const StyledTitle = styled.div`
  padding-bottom: 4.375rem;
  line-height: 1.5;
  font-size: 2.5rem;
  font-weight: bold;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const CareersHero = ({title, featuredImage, buttontText, hasJobPositions, children, ...props}) => (
  <StyledHero {...props}>
    <StyledImage
        {...getImage(featuredImage.image)?.images}
        importance="high"
        alt={featuredImage.alt}
    />
    <StyledNavbarContainer>
      {children}
    </StyledNavbarContainer>
    <StyledContentContainer>
      <StyledTitle dangerouslySetInnerHTML={{__html: convertMDContent(title)}} />
      <If condition={hasJobPositions}>
        <PrimaryButton to="#sectionJobPositions" arrowOrientation="down" transparent>{buttontText}</PrimaryButton>
      </If>
    </StyledContentContainer>
  </StyledHero>
)

CareersHero.defaultProps = {
  hasJobPositions: false,
}

CareersHero.propTypes = {
  title: PropTypes.string,
  featuredImage: PropTypes.object,
  buttontText: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasJobPositions: PropTypes.bool,
}

export default CareersHero
