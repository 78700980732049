import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const Column = ({children, ...props}) => (
  <StyledColumn {...props}>
    {children}
  </StyledColumn>
)

Column.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Column
