import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledTag = styled.span`
  color: ${({theme}) => `${theme.colors.secondary}50`};
  font-size: 0.875rem;
`

const ContractTag = ({children, ...props}) => (
  <StyledTag {...props}>
    {children}
  </StyledTag>
)

ContractTag.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContractTag
