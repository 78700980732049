import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 6.125rem;

  > div:first-child {
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  ${media.down('mobile')(css`
    flex-direction: column;
    padding: 5rem 1.5rem;

    > div:first-child,
    > div:last-child {
      width: 100%;
    }
  `)}
`

const SectionJobPositions = ({children, ...props}) => {
  return (
    <StyledSection {...props}>
      {children}
    </StyledSection>
  )
}

SectionJobPositions.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionJobPositions
