/* eslint-disable max-len */
import {useState} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {filter, find, forEach, includes, keys, map, mapValues, isEmpty} from 'lodash-es'
import {If, For, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import * as allSettings from '../../content/settings/general.json'
import * as allJobTypes from '../../content/settings/jobTypes.json'
import * as allContractTypes from '../../content/settings/contractTypes.json'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'
import CareersHero from '../components/molecules/Careers/CareersHero'
import SectionJobPositions from '../components/molecules/Careers/SectionJobPositions'
import PositionCard from '../components/atoms/PositionCard'
import JobTag from '../components/atoms/JobTag'
import ContractTag from '../components/atoms/ContractTag'
import Newsletter from '../components/molecules/Careers/Newsletter'
import FilterWrapper from '../components/atoms/FilterWrapper'
import FilterButton from '../components/inputs/FilterButton'
import Column from '../components/atoms/Column'
import Typography from '../components/atoms/Typography'


const createCounterList = (list, keyName) => (
  map(keys(list), (item) => {
    return {[`${keyName}`]: item, count: 0}
  })
)

const Careers = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data.markdownRemark.frontmatter[lang]
  const careers = map(data?.careers.edges, ({node}) => node.frontmatter[lang])
  const settings = allSettings[lang]
  const [filters, setFilters] = useState({jobType: 'all', contractType: 'all'})
  const jobTypes = createCounterList(allJobTypes[lang], 'jobType')
  const contractTypes = createCounterList(allContractTypes[lang], 'contractType')

  const visiblePositions = filter(careers, (position) => {
    if (
      (includes(filters, position.relation.mainTag) || filters.jobType === 'all') &&
      (includes(filters, position.relation.contract) || filters.contractType === 'all')
    ) {
      return true
    }
  })

  forEach(careers, (position) => {
    find(jobTypes, {jobType: position.relation.mainTag}).count += 1
    find(contractTypes, {contractType: position.relation.contract}).count += 1
  })

  const handleChange = (e) => {
    const {name, value} = e.target

    if (filter[name] === value) {
      return setFilters((prevState) => ({...prevState, [name]: 'all'}))
    }

    return setFilters((prevState) => ({...prevState, [name]: value}))
  }

  return (
    <AppShell title={pageData?.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <CareersHero
          title={pageData.hero.title}
          buttontText={pageData.hero.allPositionsButton}
          featuredImage={pageData.hero.featuredImage}
          hasJobPositions={!isEmpty(visiblePositions)}
      >
        <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      </CareersHero>
      <main>
        <If condition={!isEmpty(visiblePositions)}>
          <SectionJobPositions id="sectionJobPositions">
            <Column>
              <FilterWrapper>
                <For each="tag" of={jobTypes} index="idx">
                  <FilterButton
                      key={idx}
                      name="jobType"
                      value={tag.jobType}
                      checked={tag.jobType === filters.jobType}
                      upperIndex={tag.count}
                      onChange={handleChange}
                  >
                    {allJobTypes[lang][tag.jobType]}
                  </FilterButton>
                </For>
              </FilterWrapper>
              <FilterWrapper>
                <For each="tag" of={contractTypes} index="idx">
                  <FilterButton
                      key={idx}
                      name="contractType"
                      value={tag.contractType}
                      checked={tag.contractType === filters.contractType}
                      upperIndex={tag.count}
                      onChange={handleChange}
                  >
                    {allContractTypes[lang][tag.contractType]}
                  </FilterButton>
                </For>
              </FilterWrapper>
            </Column>
            <div>
              <Choose>
                <When condition={isEmpty(visiblePositions)}>
                  <PositionCard>
                    <Typography type="span" bold>
                      {pageData.message}
                    </Typography>
                  </PositionCard>
                </When>
                <Otherwise>
                  <For each="position" of={visiblePositions} index="idx">
                    <PositionCard key={idx} filters={filters} position={position}>
                      <div>
                        <JobTag>{allJobTypes[lang][position.relation.mainTag]}</JobTag>
                        <For each="otherTag" of={position.relation.otherTags} index="idx">
                          <JobTag key={idx}>{otherTag}</JobTag>
                        </For>
                      </div>
                      <ContractTag>{allContractTypes[lang][position.relation.contract]}</ContractTag>
                    </PositionCard>
                  </For>
                </Otherwise>
              </Choose>
            </div>
          </SectionJobPositions>
        </If>
        <Newsletter
            title={isEmpty(visiblePositions) ? pageData.newsletter.titleEmpty : pageData.newsletter.title}
            writeUsText={pageData.newsletter.writeUsText}
            settings={settings.form}
        />
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Careers($id: String!, $jobPositionKey: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        ...CareersPageFragment
      }
    }

    careers: allMarkdownRemark(filter: {frontmatter: {cs: {templateKey: {eq: $jobPositionKey}, slug: {regex: "/.*(?<!doNotRender)$/"}}}}) {
      edges {
        node {
          frontmatter {
            ...JobPositionPageFragment
          }
        }
      }
    }
  }
`

Careers.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Careers
