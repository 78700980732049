import {isEmpty} from 'lodash-es'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import BaseLink from '../BaseLink'
import Typography from '../Typography'


const StyledPosition = styled(BaseLink)`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  border: 1px solid ${({theme}) => `${theme.colors.gray}20`};
  background-color: ${({theme}) => `${theme.colors.lightGray}40`};
  padding: 1.875rem;

  &:hover,
  &:focus {
    border: 1px solid ${({theme}) => `${theme.colors.gray}50`};
    background-color: ${({theme}) => `${theme.colors.lightGray}60`};
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledTitle = styled(Typography)`
  padding-bottom: 2rem;
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PositionCard = ({position, children, ...props}) => (
  <StyledPosition to={position?.slug} {...props}>
    <If condition={!isEmpty(position)}>
      <StyledTitle variant="h2" type="h6">{position.hero.title}</StyledTitle>
    </If>
    <StyledRow>
      {children}
    </StyledRow>
  </StyledPosition>
)

PositionCard.propTypes = {
  position: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default PositionCard
