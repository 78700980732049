import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  padding: 1rem 0 4rem;

  &:last-child {
    padding-bottom: 0;
  }

  ${media.down('mobile')(css`
    padding: 0 0 4rem;

    &:last-child {
      padding-bottom: 5rem;
    }
  `)}
`

const FilterWrapper = ({children, ...props}) => (
  <StyledFilterWrapper {...props}>
    {children}
  </StyledFilterWrapper>
)

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FilterWrapper
