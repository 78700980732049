import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledButton = styled.input`
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 3px;
  background: ${({theme}) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  height: 3.75rem;
  color: ${({theme}) => theme.colors.background};
  font-size: 0.875rem;
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};
`

const SubmitButton = ({value, ...props}) => (
  <StyledButton type="submit" value={value} {...props} />
)

SubmitButton.propTypes = {
  value: PropTypes.string.isRequired,
}

export default SubmitButton
