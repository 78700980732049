import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ChevronIcon} from '../../../assets/icons'
import BaseLink from '../BaseLink'


const IconWrapper = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  right: -0.875rem;
  justify-content: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `
    opacity ${theme.global.duration} ease-in,
    transform ${theme.global.duration} ease-in
  `};
  cursor: none;
  pointer-events: none;
`

const StyledButton = styled('button').withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['transparent'].includes(prop) && defaultValidatorFn(prop)
)})`
  display: flex;
  position: relative;
  align-items: center;
  border: 0;
  border-radius: 1.5rem;
  background: none;
  cursor: pointer;
  padding: 1rem 1.5rem;
  width: fit-content;
  line-height: 1.625rem;
  color: ${({theme}) => theme.colors.text};
  font-size: 0.875rem;
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: ${({theme}) => theme.global.duration} ease-in;
    opacity: ${({transparent}) => transparent && '0.5'};
    z-index: -1;
    border-radius: 1.5rem;
    background-color: ${({theme, transparent}) => transparent ? theme.colors.background : theme.colors.lightGray};
    width: 3rem;
    height: 3rem;
    content: '';
  }

  &:hover,
  &:focus {
    text-decoration: underline;

    &::before {
      width: 100%;
    }

    ${IconWrapper} {
      transform: translate(1.75rem, -50%);
      opacity: 0;
    }
  }
`

const StyledChevron = styled(ChevronIcon).withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['arrowOrientation'].includes(prop) && defaultValidatorFn(prop)
)})`
  ${({arrowOrientation}) => arrowOrientation === 'down' && css`
    transform: rotate(90deg);
    padding-left: 0;
  `}
`

const PrimaryButton = ({to, onClick, transparent, arrowOrientation, children, ...props}) => {
  const as = to ? BaseLink : 'button'

  return (
    <StyledButton
        as={as}
        to={to}
        onClick={onClick}
        transparent={transparent}
        {...props}
    >
      {children}
      <IconWrapper>
        <StyledChevron arrowOrientation={arrowOrientation} />
      </IconWrapper>
    </StyledButton>
  )
}

PrimaryButton.defaultProps = {
  arrowOrientation: 'right',
}

PrimaryButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  transparent: PropTypes.bool,
  arrowOrientation: PropTypes.oneOf(['right', 'down']),
  children: PropTypes.node.isRequired,
}

export default PrimaryButton
