import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledContainer = styled.div`
  display: ${({hidden}) => hidden ? 'none' : 'flex'};
  position: relative;
  flex-direction: column;
  border: 1px solid ${({theme}) => `${theme.colors.gray}20`};
  border-radius: 3px;
  background-color: ${({theme}) => `${theme.colors.lightGray}40`};
  width: 100%;
  height: 3.75rem;

  &:hover,
  &:focus-within {
    border: 1px solid ${({theme}) => `${theme.colors.gray}50`};
    background-color: ${({theme}) => `${theme.colors.lightGray}60`};

    label {
      opacity: 1;
      color: ${({theme}) => theme.colors.text};
    }
  }
`

const StyledLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  transform-origin: left;
  transition: ${({theme}) => `
    opacity ${theme.global.duration} ease-in,
    transform ${theme.global.duration} ease-in
  `};
  opacity: 0.5;
  color: ${({theme}) => theme.colors.secondary};
  font-size: 0.875rem;
  pointer-events: none;

  &::first-letter {
    text-transform: capitalize;
  }
`

const StyledInput = styled.input`
  border: 0;
  background: none;
  cursor: text;
  padding: 0 1rem;
  height: 100%;
  line-height: 1.375rem;
  font-size: 1rem;

  &:focus,
  &:not(:focus):not(:placeholder-shown) {
    padding: 1rem 1rem 0;
  }

  &:focus + ${StyledLabel},
  &:not(:placeholder-shown) + ${StyledLabel} {
    transform: translateY(-1.25rem) scale(0.65);
    opacity: 1;
  }
`

const TextInput = ({name, label, value, type, onChange, required, hidden, ...props}) => {
  const handleChange = (e) => {
    const {value} = e.target
    if (onChange) onChange(name, value, e)
  }

  return (
    <StyledContainer {...props} hidden={hidden}>
      <StyledInput
          id={name}
          name={name}
          value={value}
          type={type}
          onChange={handleChange}
          placeholder=" "
          required={required}
      />
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
    </StyledContainer>
  )
}

TextInput.defaultProps = {
  type: 'text',
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default TextInput
