import PropTypes from 'prop-types'
import styled from 'styled-components'
import {FilterDashIcon} from '../../../assets/icons'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  width: 50%;
  height: 2.5rem;

  &:first-child {
    width: 100%;

    sup {
      display: none;
    }
  }
`

const StyledDash = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: opacity ${({theme}) => theme.global.duration} ease-in;
  opacity: 0;
  width: 1rem;
  height: 1rem;
`

const StyledIcon = styled(FilterDashIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const StyledText = styled.span`
  transition: transform ${({theme}) => theme.global.duration} ease-in;
  font-size: 0.875rem;
`

const StyledUpperIndex = styled.sup`
  padding-left: 0.125rem;
  vertical-align: super;
  font-size: 0.5rem;
`

const StyledInput = styled.input`
  display: none;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked {
    background: black;

    & ~ ${StyledDash} {
      opacity: 1;
    }

    & ~ ${StyledText} {
      transform: translateX(1.5rem);
      font-weight: bold;
    }
  }
`

const FilterButton = ({name, value, onChange, checked, upperIndex, children, ...props}) => (
  <StyledLabel {...props}>
    <StyledInput
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
    />
    <StyledDash>
      <StyledIcon />
    </StyledDash>
    <StyledText>
      {children}<StyledUpperIndex>{upperIndex}</StyledUpperIndex>
    </StyledText>
  </StyledLabel>
)

FilterButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  upperIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default FilterButton
