import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledTag = styled.span`
  margin: 0 0.5rem;
  border: 1px solid ${({theme}) => `${theme.colors.secondary}10`};
  border-radius: 1rem;
  background-color: ${({theme}) => `${theme.colors.secondary}10`};
  padding: 0.375rem 1rem;
  width: fit-content;
  font-size: 0.875rem;

  &:first-child {
    margin-left: 0;
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.background};
  }

  &:last-child {
    margin-right: 0;
  }
`

const JobTag = ({children, ...props}) => (
  <StyledTag {...props}>
    {children}
  </StyledTag>
)

JobTag.propTypes = {
  children: PropTypes.node.isRequired,
}

export default JobTag
